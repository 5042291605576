import * as React from "react"
import { addPropertyControls, ControlType, Color, Stack, Frame } from "framer"
import { motion, LayoutGroup } from "framer-motion"
import {
    fontStack,
    fontControls,
    fontSizeOptions,
    colorTokentoValue,
    useFontControls,
} from "https://framer.com/m/framer/default-utils.js@^0.45.0"

interface SegmentedControlProps {
    // Sizing props
    height: number
    width: number

    // Stack Props
    direction: "horizontal" | "vertical"
    distribution: string
    gap: number
    padding: number

    // Other props
    slide: boolean
    segments: string[]
    defaultSegment: number
    disabled: boolean

    // Variant props
    variant: string
    activeTextColor: string
    activeBackground: string
    activeBorderColor: string
    activeBorderSize: number
    shadow: boolean
    inactiveTextColor: string
    inactiveBackground: string
    inactiveBorderColor: string
    inactiveBorderSize: number
    disabledTextColor: string
    disabledBackground: string
    disabledBorderColor: string
    disabledBorderSize: number

    // Style props
    fontSize: number

    trackColor: string
    divider: boolean
    dividerColor: string

    // Mixed border radius props
    radius: number
    isMixedBorderRadius: boolean
    topLeftBorderRadius: number
    topRightBorderRadius: number
    bottomRightBorderRadius: number
    bottomLeftBorderRadius: number

    //Mixed Active Border Size
    isMixedActiveBorder: boolean
    topActiveBorderSize: number
    rightActiveBorderSize: number
    bottomActiveBorderSize: number
    leftActiveBorderSize: number

    // Transition props
    segmentTransition: any
    id: string

    // Font
    fontFamily?: string
    fontWeight: number

    // Event props
    onChange: (index) => {}
    onSegment1Tap: () => void
    onSegment2Tap: () => void
    onSegment3Tap: () => void
    onSegment4Tap: () => void
    onSegment5Tap: () => void

    // Framer props
    layoutId?: string | null
}

/**
 * SEGMENTED CONTROL
 *
 * @framerIntrinsicWidth 320
 * @framerIntrinsicHeight 50
 *
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 */
export function SegmentedControl(props: SegmentedControlProps) {
    const {
        // Other props
        slide,
        segments,
        defaultSegment,
        disabled,

        // Variant props
        activeTextColor,
        activeBackground,
        activeBorderColor,
        shadow,
        inactiveTextColor,
        inactiveBackground,
        inactiveBorderColor,
        inactiveBorderSize,
        disabledTextColor,
        disabledBackground,
        disabledBorderColor,
        disabledBorderSize,

        // Style props
        fontSize,
        trackColor,
        divider,
        dividerColor,

        // Transition props
        segmentTransition,

        // Event props
        onChange,
        onSegment1Tap,
        onSegment2Tap,
        onSegment3Tap,
        onSegment4Tap,
        onSegment5Tap,
        distribution,

        id,
        fontFamily,
        fontWeight,

        // Stack props
        ...stackProps
    } = props

    delete stackProps.height
    delete stackProps.width

    // Props shared by Stack and other elements
    const {
        // Mixed border radius
        radius,
        isMixedBorderRadius,
        topLeftBorderRadius,
        topRightBorderRadius,
        bottomRightBorderRadius,
        bottomLeftBorderRadius,
        gap,
        padding,

        // Mixed Active Border Size
        activeBorderSize,
        isMixedActiveBorder,
        topActiveBorderSize,
        rightActiveBorderSize,
        bottomActiveBorderSize,
        leftActiveBorderSize,
        direction,
    } = props

    const outerBorderRadius = isMixedBorderRadius
        ? `${topLeftBorderRadius}px ${topRightBorderRadius}px ${bottomRightBorderRadius}px ${bottomLeftBorderRadius}px`
        : `${radius}px`

    const innerBorderRadius = isMixedBorderRadius
        ? `${topLeftBorderRadius - padding}px ${
              topRightBorderRadius - padding
          }px ${bottomRightBorderRadius - padding}px ${
              bottomLeftBorderRadius - padding
          }px`
        : `${radius - padding}px`

    const allSegments = [
        { text: segments[0], defaultEvent: onChange, event: onSegment1Tap },
        { text: segments[1], defaultEvent: onChange, event: onSegment2Tap },
        { text: segments[2], defaultEvent: onChange, event: onSegment3Tap },
        { text: segments[3], defaultEvent: onChange, event: onSegment4Tap },
        { text: segments[4], defaultEvent: onChange, event: onSegment5Tap },
    ].slice(0, segments.length)

    const [selected, setSelected] = React.useState(defaultSegment - 1)

    React.useEffect(() => {
        setSelected(defaultSegment - 1)
    }, [defaultSegment])

    const fontStyles = useFontControls(props)

    const variants = {
        selected: {
            background: colorTokentoValue(activeBackground),
            color: colorTokentoValue(activeTextColor),
            boxShadow: shadow
                ? "0px 2px 6px rgba(0,0,0,0.1), 0px 4px 16px rgba(0,0,0,0.1)"
                : "0px 0px 0px rgba(0,0,0,0)",
            border:
                !isMixedActiveBorder &&
                `${activeBorderSize}px solid ${activeBorderColor}`,
            borderTop:
                isMixedActiveBorder &&
                `${topActiveBorderSize}px solid ${activeBorderColor}`,
            borderRight:
                isMixedActiveBorder &&
                `${rightActiveBorderSize}px solid ${activeBorderColor}`,
            borderBottom:
                isMixedActiveBorder &&
                `${bottomActiveBorderSize}px solid ${activeBorderColor}`,
            borderLeft:
                isMixedActiveBorder &&
                `${leftActiveBorderSize}px solid ${activeBorderColor}`,
        },
        notSelected: {
            background: colorTokentoValue(inactiveBackground),
            color: colorTokentoValue(inactiveTextColor),
            boxShadow: "0px 0px 0px rgba(0,0,0,0), 0px 0px 0px rgba(0,0,0,0)",
            border: `${inactiveBorderSize}px solid ${inactiveBorderColor}`,
        },
        disabled: {
            background: colorTokentoValue(disabledBackground),
            color: colorTokentoValue(disabledTextColor),
            boxShadow: "0px 0px 0px rgba(0,0,0,0)",
            border: `${disabledBorderSize}px solid ${disabledBorderColor}`,
        },
    }

    return segments.length > 0 ? (
        <LayoutGroup>
            {/* Track */}
            <motion.div
                {...stackProps}
                style={{
                    borderRadius: outerBorderRadius,
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    padding,
                    background: trackColor,
                }}
            >
                {allSegments.map((segment, index) => {
                    return (
                        <motion.div
                            key={index}
                            // Sizing props
                            style={{
                                // top: 0,
                                // left: 0,
                                // position: "absolute",
                                flex: 1,
                                height:
                                    direction === "horizontal"
                                        ? "100%"
                                        : `${100 / segments.length}% `,
                                width:
                                    direction === "horizontal" ? "1fr" : "100%",
                                borderRadius: outerBorderRadius,
                                WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                display: "flex",
                            }}
                            variants={variants}
                            transition={{
                                ease: "linear",
                            }}
                            initial={
                                disabled
                                    ? "disabled"
                                    : slide
                                    ? "notSelected"
                                    : index === defaultSegment - 1
                                    ? "selected"
                                    : "notSelected"
                            }
                            animate={
                                disabled
                                    ? "disabled"
                                    : slide
                                    ? "notSelected"
                                    : selected === index
                                    ? "selected"
                                    : "notSelected"
                            }
                            data-framer-highlight={!disabled}
                            onClick={
                                disabled
                                    ? undefined
                                    : () => {
                                          setSelected(index)
                                          if (segment.event) segment.event()
                                          if (segment.defaultEvent)
                                              segment.defaultEvent(index)
                                      }
                            }
                        >
                            <h3
                                style={{
                                    ...segmentTextStyle,
                                    fontSize,
                                    ...fontStyles,
                                }}
                            >
                                {segment?.text}
                            </h3>

                            {segments.length > 1 &&
                                index !== segments.length - 1 && (
                                    <motion.div
                                        style={{
                                            height: !divider
                                                ? 0
                                                : direction === "horizontal"
                                                ? "calc(100% - 16px)"
                                                : 1,
                                            width: !divider
                                                ? 0
                                                : direction === "horizontal"
                                                ? 1
                                                : "calc(100% - 16px)",
                                            background: divider
                                                ? dividerColor
                                                : null,
                                            marginTop: 8,
                                            marginRight: -2 + index - gap * 0,
                                        }}
                                    />
                                )}
                        </motion.div>
                    )
                })}
            </motion.div>
            {/* Sliding Segment */}
            {!disabled && slide && (
                <motion.div
                    {...stackProps}
                    layoutId={
                        stackProps.layoutId
                            ? `${stackProps.layoutId}-segment`
                            : "segment"
                    }
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        position: "absolute",
                        pointerEvents: "none",
                        top: 0,
                        left: 0,
                        padding,
                    }}
                >
                    {allSegments.map((segment, index) => {
                        const isSelected = index === selected
                        const isLast = index === allSegments.length - 1
                        const isFirst = index === 0

                        const gapSize =
                            isFirst || isLast || !isSelected ? gap / 2 : gap

                        const margin = `0 ${isLast ? 0 : gapSize}px 0 ${
                            isFirst ? 0 : gapSize
                        }px`

                        return (
                            <motion.div
                                layout
                                key={isSelected ? "selected_segment" : index}
                                style={{
                                    flex: 1,
                                    width: "100%",
                                    pointerEvents: "none",
                                    margin,
                                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                    boxShadow:
                                        shadow && isSelected
                                            ? "0px 2px 6px rgba(0,0,0,0.1), 0px 4px 16px rgba(0,0,0,0.1)"
                                            : "0px 0px 0px rgba(0,0,0,0)",
                                    display: "flex",
                                    color: isSelected
                                        ? colorTokentoValue(activeTextColor)
                                        : "transparent",
                                    background: isSelected
                                        ? colorTokentoValue(activeBackground)
                                        : "transparent",
                                    // background: "blue",
                                    borderRadius: innerBorderRadius,
                                    boxSizing: "border-box",
                                    border: isSelected
                                        ? `${activeBorderSize}px solid ${activeBorderColor}`
                                        : "0px solid rgba(0,0,0,0.0)",
                                }}
                            >
                                <h3
                                    style={{
                                        ...segmentTextStyle,
                                        fontSize,
                                        ...fontStyles,
                                        pointerEvents: "none",
                                    }}
                                >
                                    {allSegments[selected]?.text}
                                </h3>
                            </motion.div>
                        )
                    })}
                </motion.div>
            )}
        </LayoutGroup>
    ) : (
        <div style={segmentsHelperContainer}>
            <p style={{ ...segmentsHelperTextStyle, fontWeight: 600 }}>
                Segments
            </p>
            <p style={segmentsHelperTextStyle}>
                Use the properties panel to add segments.
            </p>
        </div>
    )
}

SegmentedControl.defaultProps = {
    // Sizing props
    height: 50,
    width: 320,

    // Stack props
    direction: "horizontal",

    // Other props
    segments: ["Item 1", "Item 2", "Item 3"],
    slide: true,
    defaultSegment: 1,
    disabled: false,

    // Variant props
    variant: "active",
    activeTextColor: "#0099ff",
    activeBackground: "#ffffff",
    activeBorderColor: "rgba(0, 0, 0, 0)",
    activeBorderSize: 1,
    shadow: true,
    inactiveTextColor: "#BBBBBB",
    inactiveBackground: "rgba(0, 0, 0, 0)",
    inactiveBorderColor: "rgba(0, 0, 0, 0)",
    inactiveBorderSize: 1,
    disabledTextColor: "#AAAAAA",
    disabledBackground: "#ebebeb",
    disabledBorderColor: "rgba(0, 0, 0, 0)",
    disabledBorderSize: 1,

    // Style props
    fontSize: 14,
    radius: 8,
    padding: 3,
    trackColor: "#ebebeb",
    divider: true,
    dividerColor: "rgba(0,0,0,0.05)",
    fontWeight: 600,
}

// @ts-ignore
addPropertyControls(SegmentedControl, {
    direction: {
        type: ControlType.Enum,
        displaySegmentedControl: true,
        options: ["horizontal", "vertical"],
        defaultValue: SegmentedControl.defaultProps.direction,
    },
    defaultSegment: {
        type: ControlType.Number,
        title: "Selected",
        defaultValue: SegmentedControl.defaultProps.defaultSegment,
        displayStepper: true,
        step: 1,
        min: 1,
        max: 5,
    },

    variant: {
        type: ControlType.Enum,
        title: "Variants",
        defaultValue: SegmentedControl.defaultProps.variant,
        options: ["active", "inactive", "disabled"],
        optionTitles: ["Active", "Inactive", "Disabled"],
    },

    // Active Variant Property Controls
    activeTextColor: {
        type: ControlType.Color,
        title: "Text Color",
        defaultValue: SegmentedControl.defaultProps.activeTextColor,
        hidden: ({ variant }) => variant !== "active",
    },
    activeBackground: {
        title: "Bg Color",
        type: ControlType.Color,
        defaultValue: SegmentedControl.defaultProps.activeBackground,
        hidden: ({ variant }) => variant !== "active",
    },
    activeBorderColor: {
        type: ControlType.Color,
        title: "Border Color",
        defaultValue: SegmentedControl.defaultProps.activeBorderColor,
        hidden: ({ variant }) => variant !== "active",
    },
    activeBorderSize: {
        title: "Border Width",
        type: ControlType.FusedNumber,
        defaultValue: SegmentedControl.defaultProps.activeBorderSize,
        toggleKey: "isMixedActiveBorder",
        toggleTitles: ["Border", "Border per side"],
        valueKeys: [
            "topActiveBorderSize",
            "rightActiveBorderSize",
            "bottomActiveBorderSize",
            "leftActiveBorderSize",
        ],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
        hidden: ({ variant }) => variant !== "active",
    },
    shadow: {
        title: "Shadow",
        type: ControlType.Boolean,
        defaultValue: SegmentedControl.defaultProps.shadow,
        enabledTitle: "Show",
        disabledTitle: "Hide",
        hidden: ({ variant }) => variant !== "active",
    },

    // Inactive Variant Property Controls
    inactiveTextColor: {
        type: ControlType.Color,
        title: "Text Color",
        defaultValue: SegmentedControl.defaultProps.inactiveTextColor,
        hidden: ({ variant }) => variant !== "inactive",
    },
    inactiveBackground: {
        title: "Bg Color",
        type: ControlType.Color,
        defaultValue: SegmentedControl.defaultProps.inactiveBackground,
        hidden: ({ variant }) => variant !== "inactive",
    },
    inactiveBorderColor: {
        type: ControlType.Color,
        title: "Border Color",
        defaultValue: SegmentedControl.defaultProps.inactiveBorderColor,
        hidden: ({ variant }) => variant !== "inactive",
    },
    inactiveBorderSize: {
        type: ControlType.Number,
        title: "Border Size",
        displayStepper: true,
        step: 1,
        min: 1,
        max: 25,
        defaultValue: SegmentedControl.defaultProps.inactiveBorderSize,
        hidden: ({ variant }) => variant !== "inactive",
    },

    // Disabled Variant Property Controls
    disabledTextColor: {
        type: ControlType.Color,
        title: "Text Color",
        defaultValue: SegmentedControl.defaultProps.disabledTextColor,
        hidden: ({ variant }) => variant !== "disabled",
    },
    disabledBackground: {
        type: ControlType.Color,
        title: "Bg Color",
        defaultValue: SegmentedControl.defaultProps.disabledBackground,
        hidden: ({ variant }) => variant !== "disabled",
    },
    disabledBorderColor: {
        type: ControlType.Color,
        title: "Border Color",
        defaultValue: SegmentedControl.defaultProps.disabledBorderColor,
        hidden: ({ variant }) => variant !== "disabled",
    },
    disabledBorderSize: {
        type: ControlType.Number,
        title: "Border Size",
        displayStepper: true,
        step: 1,
        min: 1,
        max: 25,
        defaultValue: SegmentedControl.defaultProps.disabledBorderSize,
        hidden: ({ variant }) => variant !== "disabled",
    },

    // Other Property Controls
    slide: {
        type: ControlType.Boolean,
        title: "Slide",
        enabledTitle: "Yes",
        disabledTitle: "No",
        defaultValue: SegmentedControl.defaultProps.slide,
    },
    segmentTransition: {
        title: "Transition",
        type: ControlType.Transition,
        // defaultValue: SegmentedControl.defaultProps.segmentTransition,
        hidden: ({ slide }) => slide === false,
    },
    trackColor: {
        type: ControlType.Color,
        title: "Track Color",
        defaultValue: SegmentedControl.defaultProps.trackColor,
    },

    radius: {
        title: "Radius",
        type: ControlType.FusedNumber,
        defaultValue: SegmentedControl.defaultProps.radius,
        toggleKey: "isMixedBorderRadius",
        toggleTitles: ["Radius", "Radius per corner"],
        valueKeys: [
            "topLeftBorderRadius",
            "topRightBorderRadius",
            "bottomRightBorderRadius",
            "bottomLeftBorderRadius",
        ],
        valueLabels: ["TL", "TR", "BR", "BL"],
        min: 0,
    },
    gap: {
        displayStepper: true,
        type: ControlType.Number,
    },
    fontSize: {
        ...fontSizeOptions,
        defaultValue: SegmentedControl.defaultProps.fontSize,
    },
    ...fontControls,
    disabled: {
        type: ControlType.Boolean,
        title: "Disabled",
        enabledTitle: "Yes",
        disabledTitle: "No",
        defaultValue: SegmentedControl.defaultProps.disabled,
    },
    divider: {
        type: ControlType.Boolean,
        title: "Dividers",
        defaultValue: SegmentedControl.defaultProps.divider,
        enabledTitle: "Show",
        disabledTitle: "Hide",
    },
    dividerColor: {
        type: ControlType.Color,
        title: "Color",
        defaultValue: SegmentedControl.defaultProps.dividerColor,
        hidden: ({ divider }) => divider === false,
    },

    // Event Property Controls
    onChange: {
        title: "On Change",
        type: ControlType.EventHandler,
    },
    onSegment1Tap: {
        title: "Segment 1 Tap",
        type: ControlType.EventHandler,
    },
    onSegment2Tap: {
        title: "Segment 2 Tap",
        type: ControlType.EventHandler,
    },
    onSegment3Tap: {
        title: "Segment 3 Tap",
        type: ControlType.EventHandler,
    },
    onSegment4Tap: {
        title: "Segment 4 Tap",
        type: ControlType.EventHandler,
    },
    onSegment5Tap: {
        title: "Segment 5 Tap",
        type: ControlType.EventHandler,
    },

    // Segment Property Controls
    segments: {
        type: ControlType.Array,
        title: "Segments",
        maxCount: 5,
        defaultValue: SegmentedControl.defaultProps.segments,
        propertyControl: {
            type: ControlType.String,
        },
    },
})

const segmentTextStyle: React.CSSProperties = {
    fontFamily: fontStack,
    // fontSize: 12,
    letterSpacing: 0,
    fontWeight: 600,
    textAlign: "center",
    margin: "auto auto",
}

const segmentsHelperContainer: React.CSSProperties = {
    width: "100%",
    height: "100%",
    borderRadius: 6,
    background: "rgba(136, 85, 255, 0.3)",
    border: "1px dashed #85F",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
}

const segmentsHelperTextStyle: React.CSSProperties = {
    fontFamily: fontStack,
    color: "#8856FF",
    fontSize: 10,
    letterSpacing: 0,
    lineHeight: 1.4,
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 0,
    maxWidth: "300px",
    textAlign: "center",
}

SegmentedControl.displayName = "Segmented Control"
